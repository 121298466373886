import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) public platformId: object,
    private oAuthService: OAuthService,
    protected router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        request.clone({
          setHeaders: this.getAdditionalHeaders(request.headers),
        })
      )
      .pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !request.url.includes('smartai/token') &&
            (error.status === 401 || error.status === 403)
          ) {
            this.oAuthService.initCodeFlow();
            //this.router.navigate(['/account/login']);
          }
          return throwError(error);
        })
      );
  }

  private getAdditionalHeaders(existingHeaders?: HttpHeaders): any {
    const headers: any = {};
      const token = this.oAuthService.getAccessToken();
      if (!(existingHeaders && existingHeaders.has('Authorization')) && token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      return headers;
    }
}
