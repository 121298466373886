import { environment } from './../environments/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Component } from '@angular/core';

export const authConfig: AuthConfig = environment.authConfig;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;
  constructor(private oAuthService: OAuthService) {
  }

  get isLogin() {
    return this.oAuthService.hasValidAccessToken()
  }
  logout() {
    this.oAuthService.logOut();
  }
  isLoggedIn() {
    if (this.oAuthService.hasValidAccessToken()) {
      return true;
    }
    return false;
  }
}
