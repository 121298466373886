import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  Icon,
  icon,
  LatLng,
  latLng,
  Layer,
  LayerGroup,
  layerGroup,
  Map,
  marker,
  MarkerOptions,
  polygon,
  svgOverlay,
  tileLayer,
} from 'leaflet';

import * as h3 from 'h3-js';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input()
  lat: number = 21.030653;
  @Input()
  lng: number = 105.84713;
  @Input()
  zoom: number = 15;
  @Input()
  center: LatLng = latLng(this.lat, this.lng);
  @Input()
  showHexLayer: boolean = false;
  @Input()
  hexHasData: any[] = [];
  @Input()
  clickToPin: boolean = false;

  map!: Map;

  markers: Layer[] = [];
  hexLayers: Layer[] = [];

  @Output()
  onMapClick: EventEmitter<any> = new EventEmitter();

  options = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')],
    zoom: this.zoom,
    center: this.center,
  };
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hexHasData && changes.hexHasData.currentValue) {
      if(this.showHexLayer) {
        this.createHexLayer();
      }
    }
  }

  ngOnInit() {}

  onMapReady(map: Map) {
    this.map = map;
    this.getCurrentLocation();
  }

  onPin(e: any) {
    if (this.clickToPin) {
      this.markers = [
        this.createMarker(this.center),
        this.createMarker(e.latlng),
      ];
    } else {
      this.markers = [
        this.createMarker(this.center)
      ];
    }

    this.onMapClick.emit(e.latlng);
  }

  getCurrentLocation() {
    //if (navigator.geolocation) {
    //  navigator.geolocation.getCurrentPosition((position) => {
    this.lat = 21.030653;
    this.lng = 105.84713;
    this.center = latLng(this.lat, this.lng);
    this.markers.push(this.createMarker(this.center));
    // });
    //} else {
    //alert('Geolocation is not supported by this browser.');
    //}
  }

  createMarker(location: LatLng, center: boolean = false) {
    return marker(latLng(location.lat, location.lng), {
      icon: icon({
        ...Icon.Default.prototype.options,
        iconUrl: 'assets/image/marker-icon.png',
        iconRetinaUrl: 'assets/image/marker-icon-2x.png',
        shadowUrl: 'assets/image/marker-shadow.png',
      }),
      title: 'Workspace',
    } as MarkerOptions);
  }

  getLayer() {
    const layers: Layer[] = [];
    layers.push(tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'));
    if (this.center) {
      layers.push(this.createMarker(this.center));
    }
    return layers;
  }

  onMapMoveEnd(e: any) {
    if (this.showHexLayer) this.createHexLayer();
  }

  polygonLayer?: LayerGroup;
  createHexLayer() {
    if (!this.map) return;
    if (this.polygonLayer) {
      this.polygonLayer.clearLayers();
      this.map.removeLayer(this.polygonLayer);
      this.polygonLayer.remove();
    }

    const bounds = this.map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const boundsPolygon = [
      [sw.lat, sw.lng],
      [ne.lat, sw.lng],
      [ne.lat, ne.lng],
      [sw.lat, ne.lng],
      [sw.lat, sw.lng],
    ];

    const h3s = h3.polyfill(boundsPolygon, 9);
    this.polygonLayer = layerGroup().addTo(this.map);
    for (const h3id of h3s) {
      const isSelected = this.hexHasData.find((f: any) => f.h3Cell === h3id);
      const style = isSelected ? { fillColor: 'orange' } : {};

      const h3Bounds = h3.h3ToGeoBoundary(h3id);
      //const averageEdgeLength = this.computeAverageEdgeLengthInMeters(h3Bounds);
      //const cellArea = h3.cellArea(h3id, 'm2');

      let tooltipText = `
        Cell ID: <b>${h3id}</b>
        <br />
        <b>Dữ liệu chưa được cập nhật</b>
        `;
      if (isSelected) {
        tooltipText = `
        Cell ID: <b>${h3id}</b>
        <br />
        Địa chỉ: <b>${isSelected.address}</b>
        <br />
        <b>Giá đất ở (VNĐ/m2):</b>
        <ul>
          <li>
            <b>VT1: ${(isSelected.vt11*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT2: ${(isSelected.vt12*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT3: ${(isSelected.vt13*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT4: ${(isSelected.vt14*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
        </ul>
        <b>Giá đất thương mại, dịch vụ (VNĐ/m2):</b>
        <ul>
          <li>
            <b>VT1: ${(isSelected.vt21*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT2: ${(isSelected.vt22*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT3: ${(isSelected.vt23*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT4: ${(isSelected.vt24*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
        </ul>
        <b>Giá đất sản xuất kinh doanh,<br />phi nông nghiệp không phải là <br />đất thương mại dịch vụ (VNĐ/m2):</b>
        <ul>
          <li>
            <b>VT1: ${(isSelected.vt31*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT2: ${(isSelected.vt32*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT3: ${(isSelected.vt33*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
          <li>
            <b>VT4: ${(isSelected.vt34*isSelected.scaleValue*1000).toLocaleString()}</b>
          </li>
        </ul>
        `;
      }

      const h3Polygon = polygon(this.h3BoundsToPolygon(h3Bounds), style)
        //.on('click', () => copyToClipboard(h3id))
        .bindTooltip(tooltipText)
        .addTo(this.polygonLayer);

      // less SVG, otherwise perf is bad
      if (Math.random() > 0.8 || isSelected) {
        var svgElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        );
        svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgElement.setAttribute('viewBox', '0 0 200 200');
        svgElement.innerHTML = `<text x="20" y="70" class="h3Text">${h3id}</text>`;
        var svgElementBounds = h3Polygon.getBounds();
        svgOverlay(svgElement, svgElementBounds).addTo(this.polygonLayer);
      }
    }
  }

  computeAverageEdgeLengthInMeters(vertexLocations: any) {
    let totalLength = 0;
    let edgeCount = 0;
    for (let i = 1; i < vertexLocations.length; i++) {
      const [fromLat, fromLng] = vertexLocations[i - 1];
      const [toLat, toLng] = vertexLocations[i];
      const edgeDistance = this.getDistanceOnEarthInMeters(
        fromLat,
        fromLng,
        toLat,
        toLng
      );
      totalLength += edgeDistance;
      edgeCount++;
    }
    return totalLength / edgeCount;
  }
  getDistanceOnEarthInMeters(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const lat1Rad = this.degreesToRadians(lat1);
    const lat2Rad = this.degreesToRadians(lat2);
    const lonDelta = this.degreesToRadians(lon2 - lon1);
    const x =
      Math.sin(lat1Rad) * Math.sin(lat2Rad) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lonDelta);
    return this.EARTH_RADIUS_METERS * Math.acos(Math.max(Math.min(x, 1), -1));
  }

  radiansToDegrees(r: number) {
    return (r * 180) / Math.PI;
  }
  degreesToRadians(d: number) {
    return (d * Math.PI) / 180;
  }

  h3BoundsToPolygon(lngLatH3Bounds: any[]) {
    lngLatH3Bounds.push(lngLatH3Bounds[0]); // "close" the polygon
    return lngLatH3Bounds;
  }
  EARTH_RADIUS_METERS = 6371000;
}
