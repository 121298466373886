<nz-layout class="app-layout">
  <nz-sider [hidden]="!isLogin"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <a href="/">
        <img
          src="https://cdn.topcv.vn/140/company_logos/950pWUZjTjhu2mANtI80S6DGI26IZgcR_1648104001____580826d65a911133dd2a914e922d467a.png"
          alt="logo"
        />
        <h1>UMEE</h1>
      </a>
    </div>
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-submenu nzOpen nzTitle="Real Estate" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/welcome">Bản đồ dữ liệu</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/land-price">Tra cứu giá BĐS</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Quản trị hệ thống" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a>Quản lý người dùng</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Đăng xuất" nzIcon="logout" *ngIf="isLoggedIn()" (click)="logout()">
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header [hidden]="!isLogin">
      <div class="app-header">
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i
              class="trigger"
              nz-icon
              [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
          </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
<router-outlet name="login"></router-outlet>
