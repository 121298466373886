<div
  style="height: 100%"
  leaflet
  [leafletZoom]="zoom"
  [leafletCenter]="center"
  (leafletMapMoveEnd)="onMapMoveEnd($event)"
  (leafletClick)="onPin($event)"
  (leafletMapReady)="onMapReady($event)"
  [leafletOptions]="options"
>
  <div *ngFor="let l of markers" [leafletLayer]="l"></div>
</div>
